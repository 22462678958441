import './index.css';

import { BOOKING_BUTTON_SIZE_LABELS, getColorPalette } from './colors';
import { ICONS } from './icons';
import isColorLight from './isColorLight';

function createCustomButton(
    text: string,
    url: string,
    color: string,
    size: string,
): HTMLAnchorElement {
    const button = document.createElement('a');
    button.href = url;
    button.role = 'button';
    button.setAttribute('aria-label', text);

    const iconElement = createIconElement(isColorLight(color) ? 'reservioDark' : 'reservioWhite');
    const textElement = createTextElement(text, size, color);

    button.appendChild(iconElement);
    button.appendChild(textElement);

    applyButtonStyles(button, size, color);

    return button;
}

function createTextElement(text: string, size: string, color: string): HTMLDivElement {
    const textElement = document.createElement('div');
    textElement.textContent = text;
    applyTextStyles(textElement, color, size);

    return textElement;
}

function createIconElement(name: keyof typeof ICONS): HTMLImageElement {
    const icon = document.createElement('img');
    icon.alt = 'Reservio';
    icon.src = ICONS[name];
    icon.height = 24;
    icon.width = 24;
    return icon;
}

function applyButtonStyles(button: HTMLElement, size: string, color: string): void {
    button.style.width = 'fit-content';
    button.style.backgroundColor = color;
    button.style.display = 'flex';
    button.style.alignItems = 'center';
    button.style.columnGap = '8px';
    button.style.borderRadius = '4px';
    button.style.cursor = 'pointer';
    button.style.transition = 'all 0.3s ease';
    button.style.maxWidth = '100%';
    button.style.textDecoration = 'none';

    switch (size) {
        case BOOKING_BUTTON_SIZE_LABELS.SMALL:
            button.style.padding = '8px 16px';
            break;
        case BOOKING_BUTTON_SIZE_LABELS.MEDIUM:
            button.style.padding = '12px 24px';
            break;
        case BOOKING_BUTTON_SIZE_LABELS.LARGE:
        default:
            button.style.padding = '16px 32px';
            break;
    }

    button.onmouseover = () => {
        button.style.backgroundColor = getColorPalette(color)['800'];
    };
    button.onmouseleave = () => {
        button.style.backgroundColor = color;
    };
    button.onfocus = () => {
        button.style.boxShadow = `0px 0px 0px 2px white, 0px 0px 0px 4px ${
            getColorPalette(color)['300']
        }`;
    };
    button.onblur = () => {
        button.style.boxShadow = 'none';
    };
}

function applyTextStyles(textElement: HTMLElement, color: string, size: string): void {
    size === BOOKING_BUTTON_SIZE_LABELS.SMALL
        ? (textElement.style.fontSize = '14px')
        : (textElement.style.fontSize = '16px');
    textElement.style.fontFamily = 'Venn, Arial, Helvetica, sans-serif';
    textElement.style.lineHeight = '150%';
    textElement.style.textOverflow = 'ellipsis';
    textElement.style.whiteSpace = 'nowrap';
    textElement.style.overflow = 'hidden';
    textElement.style.textTransform = 'uppercase';
    textElement.style.color = isColorLight(color) ? 'black' : 'white';
    textElement.style.letterSpacing = '0.02rem';
}

(function () {
    const containers = document.querySelectorAll('.reservio-button-container');
    containers.forEach((container) => {
        if (container instanceof HTMLElement) {
            const text = container.dataset.text || 'REZERVOVAT';
            const url = container.dataset.url || 'https://reservio.com';
            const color = container.dataset.color || '#0C0908';
            const size = container.dataset.size || 'small';
            const button = createCustomButton(text, url, color, size);
            if (container.firstChild) container.removeChild(container.firstChild);
            container.appendChild(button);
        }
    });
})();
