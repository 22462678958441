export const BOOKING_BUTTON_SIZE_LABELS = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

export const getNewTint = (value: number, factor: number) =>
    Math.ceil(value + (255 - value) * factor);

export const getTintColor = (color: string, factor = 0.16) => {
    const colorRGB = hexToRgb(color);

    const newRed = getNewTint(colorRGB.r, factor);
    const newGreen = getNewTint(colorRGB.g, factor);
    const newBlue = getNewTint(colorRGB.b, factor);

    return rgbToHex(newRed, newGreen, newBlue);
};

export const getNewShade = (value: number, factor: number) => {
    return Math.ceil(value * (1 - factor));
};

export const getShadeColor = (color: string, factor = 0.2) => {
    const colorRGB = hexToRgb(color);

    const newRed = getNewShade(colorRGB.r, factor);
    const newGreen = getNewShade(colorRGB.g, factor);
    const newBlue = getNewShade(colorRGB.b, factor);

    return rgbToHex(newRed, newGreen, newBlue);
};

export const componentToHex = (value: number) => {
    const hex = value.toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hexToRgb(hex: any) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/iu.exec(hex);

    return result
        ? {
              b: parseInt(result[3], 16),
              g: parseInt(result[2], 16),
              r: parseInt(result[1], 16),
          }
        : {
              b: 0,
              g: 0,
              r: 0,
          };
}

export const rgbToHex = (red: number, green: number, blue: number) => {
    return `#${componentToHex(red)}${componentToHex(green)}${componentToHex(blue)}`;
};

export const getColorPalette = (color: string) => {
    return {
        100: getTintColor(color, 0.9),
        200: getTintColor(color, 0.84),
        300: getTintColor(color, 0.68),
        400: getTintColor(color, 0.52),
        500: getTintColor(color, 0.36),
        600: getTintColor(color, 0.12),
        700: color,
        800: getShadeColor(color),
    };
};
